import getSuperagent from "../../helpers/superagent-with-auth"
import { baseUrl } from "../../constants"

export const authActions = {
  SET_TOKEN: 'SET_TOKEN',
};

export const setToken = (token) => {
  return {
    type: authActions.SET_TOKEN,
    payload: {
      token
    }
  }
}

export const loginUser = (username, password) => async (dispatch, getState) => {
  const { token } = await getSuperagent()
    .post(`${baseUrl}/api/auth/token`)
    .send({ username, password })
    .end();

  dispatch({
    type: authActions.SET_TOKEN, payload: {
      token
    }
  });
}
