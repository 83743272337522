// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-groups-addresses-js": () => import("./../src/pages/groups/addresses.js" /* webpackChunkName: "component---src-pages-groups-addresses-js" */),
  "component---src-pages-groups-js": () => import("./../src/pages/groups.js" /* webpackChunkName: "component---src-pages-groups-js" */),
  "component---src-pages-groups-status-js": () => import("./../src/pages/groups/status.js" /* webpackChunkName: "component---src-pages-groups-status-js" */),
  "component---src-pages-guests-js": () => import("./../src/pages/guests.js" /* webpackChunkName: "component---src-pages-guests-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

