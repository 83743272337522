import store from "store";
import _ from 'lodash';

export const TOKEN_KEY = 'sm-token';

export const getTokenFromStore = () => {
  return store.get(TOKEN_KEY);
}

export const setTokenInStore = (token) => {
  return store.set(TOKEN_KEY, token)
}

export const debouncedSetTokenInStore = _.debounce(setTokenInStore, 500);
