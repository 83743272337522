import { setTokenInStore } from "./tokenHelper"

export default (token = null) => {
  let fetchOptions = {
    method: null,
    url: null,
    headers: {
      'Accept': 'application/json',
      'Content-Type': 'application/json',
      'Authorization': `Bearer ${token}`
    },
    data: null
  };

  const options = {
    set: (header, value) => {
      fetchOptions[header] = value;

      return options;
    },
    send: (data) => {
      fetchOptions.data = fetchOptions.data || {};

      fetchOptions.data = {
        ...fetchOptions.data,
        ...data
      }

      return options;
    },
    end: async () => {
      const result = await fetch(fetchOptions.url, {
        method: fetchOptions.method,
        body: fetchOptions.data ? JSON.stringify(fetchOptions.data) : undefined,
        headers: fetchOptions.headers ? new Headers(fetchOptions.headers) : undefined
      });

      if(result.status === 401) {
        setTokenInStore(null);
        window.location.reload(false);
      }

      return await result.json();
    }
  }

  const setUrl = (method) => (url) => {
    fetchOptions.method = method;
    fetchOptions.url = url;

    return options;
  }

  return {
    get: setUrl('get'),
    put: setUrl('put'),
    post: setUrl('post'),
    delete: setUrl('delete'),
  }
}
