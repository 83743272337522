import getSuperagent from "../../helpers/superagent-with-auth"
import { baseUrl } from "../../constants"

export const guestActions = {
  CLEAR_CHANGES: 'CLEAR_CHANGES',
  GET_ALL_GUESTS: 'GET_ALL_GUESTS',
  GET_ALL_GUEST_GROUPS: 'GET_ALL_GUEST_GROUPS',
  GET_ALL_GUEST_GROUP_XREFS: 'GET_ALL_GUEST_GROUP_XREFS',
  GUEST_UPDATED: 'GUEST_UPDATED',
  GUEST_INSERTED: 'GUEST_INSERTED',
  SET_CURRENT_UPDATES: 'SET_CURRENT_UPDATES',
  SET_CURRENT_GROUP_UPDATES: 'SET_CURRENT_GROUP_UPDATES',
  GUEST_GROUP_UPDATED: 'GUEST_GROUP_UPDATED',
};

export const getAllGuests = () => async (dispatch, getState) => {
  const { token } = getState();

  const guests = await getSuperagent(token)
    .get(`${baseUrl}/api/guests`)
    .end();


  dispatch({
    type: guestActions.GET_ALL_GUESTS, payload: {
      guests: guests
    }
  });
}

export const getAllGuestGroups = () => async (dispatch, getState) => {
  const { token } = getState();

  const guestsGroups = await getSuperagent(token)
    .get(`${baseUrl}/api/groups`)
    .end();

  dispatch({
    type: guestActions.GET_ALL_GUEST_GROUPS, payload: {
      guestsGroups: guestsGroups
    }
  });
}

export const getAllGuestGroupXrefs = () => async (dispatch, getState) => {
  const { token } = getState();

  const guestsGroupXrefs = await getSuperagent(token)
    .get(`${baseUrl}/api/groups/xrefs`)
    .end();

  dispatch({
    type: guestActions.GET_ALL_GUEST_GROUP_XREFS, payload: {
      guestsGroupXrefs: guestsGroupXrefs
    }
  });
}

export const saveGuest = (guest) => async (dispatch, getState) => {
  const { token } = getState();

  if(guest.guestId) {
    const response = await getSuperagent(token)
      .put(`${baseUrl}/api/guests`)
      .send(guest)
      .end();

    dispatch({
      type: guestActions.GUEST_UPDATED, payload: {
        updatedGuest: response
      }
    });
  } else {
    const response = await getSuperagent(token)
      .post(`${baseUrl}/api/guests`)
      .send(guest)
      .end();

    dispatch({
      type: guestActions.GUEST_INSERTED, payload: {
        insertedGuest: response
      }
    });
  }
}

export const clearChanges = () => {
  return {
    type: guestActions.CLEAR_CHANGES
  };
}

export const setCurrentUpdates = (guestId, values) => {
  return {
    type: guestActions.SET_CURRENT_UPDATES, payload: {
      guestId,
      values
    }
  };
}

export const setCurrentGroupUpdates = (guestGroupId, values) => {
  return {
    type: guestActions.SET_CURRENT_GROUP_UPDATES, payload: {
      guestGroupId,
      values
    }
  };
}

export const saveGuestGroup = (guestGroup) => async (dispatch, getState) => {
  const { token } = getState();

  const response = await getSuperagent(token)
    .put(`${baseUrl}/api/groups`)
    .send(guestGroup)
    .end();

  dispatch({
    type: guestActions.GUEST_GROUP_UPDATED, payload: {
      updatedGuestGroup: response
    }
  });
}

export const mergeGroupUpdates = (groupUpdates, callback) => async (dispatch, getState) => {
  const { token } = getState();

  await getSuperagent(token)
    .post(`${baseUrl}/api/groups/xrefs/mergeUpdates`)
    .send(groupUpdates)
    .end();

  callback();

  dispatch(getAllGuests());
  dispatch(getAllGuestGroups());
  dispatch(getAllGuestGroupXrefs());
}
