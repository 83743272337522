/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import './src/assets/bootstrap.css';
import "./src/assets/site.css";
import 'react-table/react-table.css';
import wrapWithProvider from './src/wrap-with-provider';
import 'jquery/dist/jquery.min.js'
import 'bootstrap/js/dist/collapse'
import 'bootstrap/js/dist/carousel'
import 'bootstrap/js/dist/dropdown'

export const wrapRootElement = wrapWithProvider;
