import _ from 'lodash';
import { createStore as reduxCreateStore, applyMiddleware } from "redux";
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import { getTokenFromStore, setTokenInStore } from "../helpers/tokenHelper"
import { authActions } from "./actions/authActions"
import { guestActions } from "./actions/guestActions"

const initialState = {
  token: getTokenFromStore(),
  guests: [],
  guestsGroups: [],
  guestsGroupXrefs: [],
  currentUpdates: {},
  currentGroupUpdates: {},
}

const reducer = (state, action) => {
  switch (action.type) {
    case authActions.SET_TOKEN: {
      const { token } = action.payload;

      setTokenInStore(token);

      return {
        ...state,
        token: token
      }
    }
    case guestActions.CLEAR_CHANGES: {
      return {
        ...state,
        currentUpdates: {},
        currentGroupUpdates: {},
      }
    }
    case guestActions.GET_ALL_GUESTS: {
      const { guests } = action.payload;

      return {
        ...state,
        guests: _.sortBy(guests, 'guestId')
      }
    }
    case guestActions.GUEST_UPDATED: {
      const { updatedGuest } = action.payload;

      const currentUpdates = { ...state.currentUpdates };

      delete currentUpdates[updatedGuest.guestId];

      return {
        ...state,
        currentUpdates: currentUpdates,
        guests: _.map(state.guests, (guest) => {
          if(guest.guestId !== updatedGuest.guestId) {
            return guest;
          }

          return updatedGuest;
        })
      }
    }
    case guestActions.GUEST_INSERTED: {
      const { insertedGuest } = action.payload;

      const currentUpdates = { ...state.currentUpdates };

      delete currentUpdates[0];

      return {
        ...state,
        currentUpdates: currentUpdates,
        guests: state.guests.concat(insertedGuest)
      }
    }
    case guestActions.GET_ALL_GUEST_GROUPS: {
      const { guestsGroups } = action.payload;

      return {
        ...state,
        guestsGroups: guestsGroups
      }
    }
    case guestActions.GET_ALL_GUEST_GROUP_XREFS: {
      const { guestsGroupXrefs } = action.payload;

      return {
        ...state,
        guestsGroupXrefs: guestsGroupXrefs
      }
    }
    case guestActions.SET_CURRENT_UPDATES: {
      const { guestId, values } = action.payload;

      const currentUpdates = {
        ...state.currentUpdates,
        [guestId]: {
          ..._.get(state.currentUpdates, guestId, {}),
          ...values
        }
      };

      return {
        ...state,
        currentUpdates: currentUpdates
      }
    }
    case guestActions.SET_CURRENT_GROUP_UPDATES: {
      const { guestGroupId, values } = action.payload;

      const currentGroupUpdates = {
        ...state.currentGroupUpdates,
        [guestGroupId]: {
          ..._.get(state.currentGroupUpdates, guestGroupId, {}),
          ...values
        }
      };

      return {
        ...state,
        currentGroupUpdates: currentGroupUpdates
      }
    }
    case guestActions.GUEST_GROUP_UPDATED: {
      const { updatedGuestGroup } = action.payload;

      const currentGroupUpdates = { ...state.currentGroupUpdates };

      delete currentGroupUpdates[updatedGuestGroup.guestGroupId];

      return {
        ...state,
        currentGroupUpdates: currentGroupUpdates,
        guestsGroups: _.map(state.guestsGroups, (guestGroup) => {
          if(guestGroup.guestGroupId !== updatedGuestGroup.guestGroupId) {
            return guestGroup;
          }

          return updatedGuestGroup;
        })
      }
    }
    default: {
      return state;
    }
  }
}



const createStore = () => reduxCreateStore(reducer, initialState, applyMiddleware(thunk, logger))
export default createStore
